import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { List, SimpleList, Datagrid, TextField, EmailField } from 'react-admin';

export const UserList = (props: any) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            {isMobile ? (
                <SimpleList
                    primaryText={props => props.name}
                    secondaryText={props => props.username}
                    tertiaryText={props => props.email}
                />
            ) : (
                <Datagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="username" />
                    <EmailField source="email" />
                    <TextField source="phone" />
                </Datagrid>
            )}
        </List>
    );
};