import type { FC } from "react";
import './App.scss';

import { Admin, Resource } from 'react-admin';
import { VerifiedUser } from '@mui/icons-material';
import { AuthProvider } from './authProvider';
import { Dashboard } from './pages/dashboard';
import { UserList } from './resources/users';

import jsonServerProvider from 'ra-data-json-server';

const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

export const App: FC = () => (
  <Admin dashboard={Dashboard} authProvider={AuthProvider} dataProvider={dataProvider}>
    <Resource name="users" list={UserList} icon={VerifiedUser} />
  </Admin>
);